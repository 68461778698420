import React from "react"
import comingSoon from "../../images/coming-soon.svg";

export default function Dashboard() {
    return (
        <div className="content-center">

            <img
                alt="Coming Soon"
                className="block mx-auto w-1/2"
                src={comingSoon}
            />
            <h2 className="text-xl block mx-auto w-1/2 ">
                We just working on that...
            </h2>

        </div>
    )
}
